import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Link } from "react-router-dom";
import iconUpgrade from '../css/icons/icon-congrats-purple.png';

const UpgradeOptions = (props) => {
    
    const mURL = props.monthlyURL;
    const yURL = props.yearlyURL;

    return (
        <Popup open={props.open} closeOnDocumentClick onClose={props.onClose} className='xclPop'>
            <>
                <div className='upgradeIcon' style={{ backgroundImage: `url('${iconUpgrade}')` }}></div>
                <div className='modalContent'>
                    <h2>Upgrade Options</h2>
                    <p>So, you want to upgrade to the <strong>Build Pro</strong> plan. Good choice!</p>
                    <p>Please select which Subscription option you'd like to proceed with.</p>
                    <Link className='optionButton' to={mURL}>Monthly @ $299/month</Link><br/> or <br/><Link className='optionButton bestValue' to={yURL}>Yearly @ $2870.40/year (a 25% discount)</Link>
                </div>            
                <div className='controls'>
                    <Link className='cancelBtn' onClick={props.onClose}>Cancel</Link>
                </div>
            </>
        </Popup>
    );


}

export default UpgradeOptions;