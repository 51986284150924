import React, { useState, useEffect } from 'react';
import {useDropzone} from 'react-dropzone';
import downloadIcon from '../css/icons/icon-upload.png';
import fileIcon from '../css/icons/icon-file.png';
import loadingIcon from '../css/icons/icon-loading.gif';
import getUserData from '../xcl_modules/userData';
import getTokenData from '../xcl_modules/tokenData';
import dropzoneStyle from '../xcl_modules/dropzoneStyle';
import Swal from 'sweetalert2';

const downloadIconAlt = "upload file icon";
const fileIconAlt = "file icon";
const loadingIconAlt = "loading icon";

function DropzoneContent(props) {

  const [uploadedFileSrc, setUploadedFileSrc] = useState( props.contenturl );
  const [uploadedFileName, setUploadedFileName] = useState( props.filename );
  const [uploadedFileType, setUploadedFileType] = useState( props.filetype );
  const [uploadedFileTypeReadable, setUploadedFileTypeReadable] = useState( "" );
  const [uploadInProgress, setUploadState] = useState( false );

  //const acceptedFileTypes = ['image/png','image/jpeg','image/jpg','application/zip','application/x-zip-compressed','application/vnd.openxmlformats-officedocument.presentationml.presentation','application/vnd.openxmlformats-officedocument.wordprocessingml.document','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','application/vnd.ms-powerpoint','application/msword','application/vnd.ms-excel','audio/mpeg','video/mp4','application/pdf'];
  const acceptedFileTypes = ['image/png','image/jpeg','image/jpg','application/zip','application/x-zip-compressed','application/vnd.openxmlformats-officedocument.presentationml.presentation','application/vnd.openxmlformats-officedocument.wordprocessingml.document','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','application/vnd.ms-powerpoint','application/msword','application/vnd.ms-excel','audio/mpeg','video/mp4'];
  //const types = ['png','jpeg','jpg','zip','zip','pptx','docx','xlsx','ppt','doc','xls','mpeg','mp4','pdf'];
  const types = ['png','jpeg','jpg','zip','zip','pptx','docx','xlsx','ppt','doc','xls','mpeg','mp4'];

  const { getRootProps, getInputProps, open } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    uploadMulitple: false,
    maxFiles: 1,
    name: "logo_uploaded",
    noKeyboard: true,
    onDrop: (acceptedFiles, fileRejections) => {
    
      //const acceptedFileTypes = ['image/png','image/jpeg','image/jpg','application/zip','application/x-zip-compressed','application/vnd.openxmlformats-officedocument.presentationml.presentation','application/vnd.openxmlformats-officedocument.wordprocessingml.document','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','application/vnd.ms-powerpoint','application/msword','application/vnd.ms-excel','audio/mpeg','video/mp4','application/pdf'];
          
      if (fileRejections.length>0) {
        Swal.fire({
          position: "top",
          iconHtml:  '<div class="correctitoast"></div>',
          title: "Please limit upload to a single file.",
          showConfirmButton: false,
          timer: 2000,
          toast: true,
          timerProgressBar: true,
          showClass: {
              popup: 'toastWarningOn'
            },
          hideClass: {
              popup: 'toastWarningOff'
          }
        });
      } else {
        //console.log("start file read");
        setUploadState(true);
        acceptedFiles.forEach((file) => {
          const reader = new FileReader();
    
          reader.onabort = () => console.log('file reading was aborted')
          reader.onerror = () => console.log('file reading has failed')
          
          reader.onload = () => {

            // test the size, file.size is in KB (divide the props.storageAvailble by 1024)
            const storageAvail = (props.storageAvailable);

            if (file.size > storageAvail) {
              setUploadState(false);
              Swal.fire({
                position: "top",
                iconHtml:  '<div class="correctitoast"></div>',
                title: "Please select a different file, you don't have enough remaining storage for that one.",
                showConfirmButton: false,
                timer: 2000,
                toast: true,
                timerProgressBar: true,
                showClass: {
                    popup: 'toastWarningOn'
                  },
                hideClass: {
                    popup: 'toastWarningOff'
                }
              });
      
            } else {

              // Do whatever you want with the file contents
              if (acceptedFileTypes.indexOf(file.type) > -1) {
                // do a thing with a thing or two
                const userData = getUserData();
                const tokenData = getTokenData();
    
                // create a sudo form
                const formData = new FormData();
                formData.append("accountID", userData.accountID);
                formData.append("linkID", props.linkID);
                const loc = acceptedFileTypes.indexOf(file.type);
                formData.append("ft", types[loc]);
                formData.append("ftu", file);
    
                fetch(process.env.REACT_APP_BE+'/uploadcontent', {
                  method: 'POST',
                  headers: { Authorization: `Bearer ${tokenData.accessToken}`},
                  body: formData
                }).then((response) => { 
                  return response.json().then((data) => {
                      setUploadedFileSrc(data.pathToFile);
                      setUploadedFileName(file.name);
                      setUploadedFileType(file.type);
                      
                      setUploadedFileTypeReadable(data.filetype);

                      setUploadState(false);

                      props.onChange('contenturl', data.pathToFile, 'filetype', data.filetype, data.objectid);
                      return data;
                  }).catch((err) => {
                      console.log(err);
                  }) 
                });
    
              } else {
                setUploadState(false);          
                Swal.fire({
                  position: "top",
                  iconHtml:  '<div class="correctitoast"></div>',
                  //title: "Please limit uploads to .ZIP, .PPTX, .DOC, .XLSX, .MP4, .PDF, PNG, or JPG files, you attempted to upload ("+file.type+").",
                  title: "Please limit uploads to .ZIP, .PPTX, .DOC, .XLSX, .MP4, PNG, or JPG files, you attempted to upload ("+file.type+").",
                  showConfirmButton: false,
                  timer: 3000,
                  toast: true,
                  timerProgressBar: true,
                  showClass: {
                      popup: 'toastWarningOn'
                    },
                  hideClass: {
                      popup: 'toastWarningOff'
                  }
                });
  
              }
            }
          }
          reader.readAsArrayBuffer(file);
          //console.log("end file read");
        })
      }
    }
  });

  const dzButton = {
      color: "#513AD1",
      backgroundColor: "transparent",
      padding: "0px",
      textDecorationLine: "underline",
      border: "none",
      outline: "none"
  };

  const dlIcon = {
      display: "inline-block",
      margin: "25px auto 0px auto"
  }

  useEffect(() => {
    
    if (uploadedFileType!=="") {
        //const mimeTypes = ['image/png','image/jpeg','image/jpg','application/zip','application/x-zip-compressed','application/vnd.openxmlformats-officedocument.presentationml.presentation','application/vnd.openxmlformats-officedocument.wordprocessingml.document','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','application/vnd.ms-powerpoint','application/msword','application/vnd.ms-excel','audio/mpeg','video/mp4','application/pdf'];
        const mimeTypes = ['image/png','image/jpeg','image/jpg','application/zip','application/x-zip-compressed','application/vnd.openxmlformats-officedocument.presentationml.presentation','application/vnd.openxmlformats-officedocument.wordprocessingml.document','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','application/vnd.ms-powerpoint','application/msword','application/vnd.ms-excel','audio/mpeg','video/mp4'];
        //const types = ['png','jpeg','jpg','zip','zip','pptx','docx','xlsx','ppt','doc','xls','mpeg','mp4','pdf'];
        const types = ['png','jpeg','jpg','zip','zip','pptx','docx','xlsx','ppt','doc','xls','mpeg','mp4'];

        let readAbleType = "";
        if (uploadedFileType.length > 6) {
          readAbleType = types[mimeTypes.indexOf(uploadedFileType)];
        } else {
          readAbleType = uploadedFileType;
        }
    
        setUploadedFileTypeReadable(readAbleType);
    }

  },[uploadedFileType]);


  const doChangeFile = () => {
    setUploadedFileSrc("");
    setUploadedFileType("");
    setUploadedFileTypeReadable("");
    props.onChange('contenturl', '', 'filetype', ''); // needs to be a space bro, or the component will unload (adminsettings.js rule)
  }

  return (
    <div className="container">
      <style>{dropzoneStyle}</style>
      <label htmlFor='logo_uploaded'>Upload a File</label>
      <p>
      File types: .ZIP, .PPTX, .DOC, .XLSX, .MP4, PNG, or JPG<br/>
      Storage Available: {Math.round(props.storageAvailable/1048576)} MB<br/>
      </p>
      {
        (uploadedFileSrc) ? (
          <>
            <div className="dropzone">
                <img src={fileIcon} alt={fileIconAlt} style={dlIcon} /><br/>
                <div className='fileNameBadge' title={`${uploadedFileTypeReadable} file type`} >{uploadedFileTypeReadable}</div>
                <div>{uploadedFileName || props.filename || ""}</div>
            </div>
            <input type="button" className="changeImageButton" href='#' onClick={doChangeFile} value="Change File"></input>
          </>
        ) : 
           (uploadInProgress) ? (
            <>
              <div className="dropzone">
                  <img src={loadingIcon} alt={loadingIconAlt} style={dlIcon} /><br/>
                  <div role="alert">Your file is uploading, please wait.</div>
              </div>
            </>
            ) : (
            <>
              <div {...getRootProps({className: 'dropzone'})}>
              <input {...getInputProps()} />
              <img src={downloadIcon} alt={downloadIconAlt} style={dlIcon} />
                <br/>
                Drag 'n' drop file here<br/>
                or<br/>
                <button type="button" style={dzButton} onClick={open}>
                  Choose file
                </button>
              </div>
            </>
          )}
          
      
    </div>
  );
}

export default DropzoneContent;